import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import adminSagas from './Admin';
import userSagas from './User';
import registrantSagas from './Registrant';
import enterpriseSagas from './Enterprise';
import ibgeSagas from './Ibge';
import participantSaga from './Participant';
import sourceSaga from './Source';
import technologySaga from './Tecnology';
import recSaga from './Rec';
import notaFiscalSaga from './NotaFiscal';
import cepSaga from './Cep';
import cegSaga from './Ceg';
import fileSaga from './File';
import adminDashboard from './AdminDashboard';
import clientDashboard from './ClientDashboard';
import rdmsSaga from './Rdms';
import generationSaga from './Generation';
import recoverPasswordSaga from './RecoverPassword';
import apiIntegration from './ApiIntegration';
import tutorial from './Tutorial';
import evident from './Evident';
import config from './Config';
import countrySaga from './Country';
import retirementSaga from './Retirement';
import beneficiarySaga from './Beneficiary';
import redemptionSaga from './Redemption';
import transferSaga from './Transfer';
import NotaFiscalRedemptionSaga from './NotaFiscalRedemption';
import beneficiaryConsumptionSaga from './BeneficiaryConsumption';
import participantRegistrantSaga from './ParticipantRegistrant';
import participantEnterpriseSaga from './ParticipantEnterprise';
import redemptionAutomation from './RedemptionAutomation';
import serpro from './Serpro';
import exchange from './Exchange';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    adminSagas(),
    userSagas(),
    registrantSagas(),
    enterpriseSagas(),
    ibgeSagas(),
    participantSaga(),
    sourceSaga(),
    technologySaga(),
    recSaga(),
    notaFiscalSaga(),
    cepSaga(),
    cegSaga(),
    fileSaga(),
    adminDashboard(),
    clientDashboard(),
    rdmsSaga(),
    generationSaga(),
    recoverPasswordSaga(),
    apiIntegration(),
    tutorial(),
    evident(),
    config(),
    countrySaga(),
    retirementSaga(),
    beneficiarySaga(),
    redemptionSaga(),
    transferSaga(),
    NotaFiscalRedemptionSaga(),
    beneficiaryConsumptionSaga(),
    participantRegistrantSaga(),
    participantEnterpriseSaga(),
    redemptionAutomation(),
    serpro(),
    exchange()
  ]);
}
