export const Types = {
  GET_EXCHANGE: 'GET_EXCHANGE',
  GET_EXCHANGE_SUCCESS: 'GET_EXCHANGE_SUCCESS',
  GET_EXCHANGE_ERROR: 'GET_EXCHANGE_ERROR',

  DELETE_EXCHANGE: 'DELETE_EXCHANGE',
  DELETE_EXCHANGE_SUCCESS: 'DELETE_EXCHANGE_SUCCESS',
  DELETE_EXCHANGE_ERROR: 'DELETE_EXCHANGE_ERROR',

  EXCHANGE_CLEAN: 'EXCHANGE_CLEAN',
};

const INIT_STATE = {
  data: [],
  exchange: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  amount: 10,
};

export default function Exchange(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_EXCHANGE:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case Types.GET_EXCHANGE_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_EXCHANGE_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload,
      };

    case Types.DELETE_EXCHANGE:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: false,
      };
    case Types.DELETE_EXCHANGE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: false,
      };
    case Types.DELETE_EXCHANGE_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };

    case Types.EXCHANGE_CLEAN:
      return {
        ...state,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  getExchanges: (payload) => ({
    type: Types.GET_EXCHANGE,
    payload,
  }),
  getExchangesSuccess: (payload) => ({
    type: Types.GET_EXCHANGE_SUCCESS,
    payload,
  }),
  getExchangesError: (payload) => ({
    type: Types.GET_EXCHANGE_ERROR,
    payload,
  }),

  deleteExchange: (payload) => ({
    type: Types.DELETE_EXCHANGE,
    payload,
  }),
  deleteExchangeSuccess: (payload) => ({
    type: Types.DELETE_EXCHANGE_SUCCESS,
    payload,
  }),
  deleteExchangeError: (payload) => ({
    type: Types.DELETE_EXCHANGE_ERROR,
    payload,
  }),

  exchangeClean: () => ({
    type: Types.EXCHANGE_CLEAN,
  }),
};
