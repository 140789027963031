import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as ExchangeTypes,
  Creators as ExchangeActions,
} from '../ducks/Exchange';
import api from '../../util/Api';

function* getExchanges({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/exchanges', {
      params: payload,
    });
    if (status === 200) {
      yield put(ExchangeActions.getExchangesSuccess(data));
    }
  } catch (error) {
    yield put(
      ExchangeActions.getExchangesError('Erro ao listar exchanges')
    );
  }
}

function* deleteExchange({ payload }) {
  try {
    const { status } = yield call(api.delete, `/exchanges/${payload}`);
    if (status === 200) {
      yield put(ExchangeActions.deleteExchangeSuccess());
    }
  } catch (error) {
    yield put(
      ExchangeActions.deleteExchangeError('Erro ao deletar exchange')
    );
  }
}

function* getExchangesWatcher() {
  yield takeLatest(ExchangeTypes.GET_EXCHANGE, getExchanges);
}

function* deleteExchangeWatcher() {
  yield takeLatest(ExchangeTypes.DELETE_EXCHANGE, deleteExchange);
}

export default function* rootSaga() {
  yield all([
    fork(getExchangesWatcher),
    fork(deleteExchangeWatcher),
  ]);
}
