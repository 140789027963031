export const Types = {
  GET_NOTAS_REDEMPTION: 'GET_NOTAS_REDEMPTION',
  GET_NOTAS_REDEMPTION_SUCCESS: 'GET_NOTAS_REDEMPTION_SUCCESS',
  GET_NOTAS_REDEMPTION_ERROR: 'GET_NOTAS_REDEMPTION_ERROR',

  PUT_NOTAS_REDEMPTION: 'PUT_NOTAS_REDEMPTION',
  PUT_NOTAS_REDEMPTION_SUCCESS: 'PUT_NOTAS_REDEMPTION_SUCCESS',
  PUT_NOTAS_REDEMPTION_ERROR: 'PUT_NOTAS_REDEMPTION_ERROR',
  PUT_NOTAS_REDEMPTION_RESET: 'PUT_NOTAS_REDEMPTION_RESET',

  CLONE_NOTAS_REDEMPTION: 'CLONE_NOTAS_REDEMPTION',
  CLONE_NOTAS_REDEMPTION_SUCCESS: 'CLONE_NOTAS_REDEMPTION_SUCCESS',
  CLONE_NOTAS_REDEMPTION_ERROR: 'CLONE_NOTAS_REDEMPTION_ERROR',
  CLONE_NOTAS_REDEMPTION_RESET: 'CLONE_NOTAS_REDEMPTION_RESET',

  PAID_NOTAS_REDEMPTION: 'PAID_NOTAS_REDEMPTION',
  PAID_NOTAS_REDEMPTION_SUCCESS: 'PAID_NOTAS_REDEMPTION_SUCCESS',
  PAID_NOTAS_REDEMPTION_ERROR: 'PAID_NOTAS_REDEMPTION_ERROR',
  PAID_NOTAS_REDEMPTION_RESET: 'PAID_NOTAS_REDEMPTION_RESET',

  CURRENCY_CONVERSION_NOTAS_REDEMPTION: 'CURRENCY_CONVERSION_NOTAS_REDEMPTION',
  CURRENCY_CONVERSION_NOTAS_REDEMPTION_SUCCESS: 'CURRENCY_CONVERSION_NOTAS_REDEMPTION_SUCCESS',
  CURRENCY_CONVERSION_NOTAS_REDEMPTION_ERROR: 'CURRENCY_CONVERSION_NOTAS_REDEMPTION_ERROR',
  CURRENCY_CONVERSION_NOTAS_REDEMPTION_RESET: 'CURRENCY_CONVERSION_NOTAS_REDEMPTION_RESET',

  PAID_NOTAS_REDEMPTION_BY_NUMBER: 'PAID_NOTAS_REDEMPTION_BY_NUMBER',
  PAID_NOTAS_REDEMPTION_BY_NUMBER_SUCCESS: 'PAID_NOTAS_REDEMPTION_BY_NUMBER_SUCCESS',
  PAID_NOTAS_REDEMPTION_BY_NUMBER_ERROR: 'PAID_NOTAS_REDEMPTION_BY_NUMBER_ERROR',
  PAID_NOTAS_REDEMPTION_BY_NUMBER_RESET: 'PAID_NOTAS_REDEMPTION_BY_NUMBER_RESET',

  GET_NOTA_REDEMPTION_BY_ID: 'GET_NOTA_REDEMPTION_BY_ID',
  GET_NOTA_REDEMPTION_BY_ID_SUCCESS: 'GET_NOTA_REDEMPTION_BY_ID_SUCCESS',
  GET_NOTA_REDEMPTION_BY_ID_ERROR: 'GET_NOTA_REDEMPTION_BY_ID_ERROR',
  GET_NOTA_REDEMPTION_BY_ID_RESET: 'GET_NOTA_REDEMPTION_BY_ID_RESET',

  RECEIPT_UPLOAD_REDEMPTION: 'RECEIPT_UPLOAD_REDEMPTION',
  RECEIPT_UPLOAD_REDEMPTION_SUCCESS: 'RECEIPT_UPLOAD_REDEMPTION_SUCCESS',
  RECEIPT_UPLOAD_REDEMPTION_ERROR: 'RECEIPT_UPLOAD_REDEMPTION_ERROR',
  RECEIPT_UPLOAD_REDEMPTION_RESET: 'RECEIPT_UPLOAD_REDEMPTION_RESET',
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: [],

  amount: 10,

  putLoading: false,
  putSuccess: false,
  putError: false,

  cloneLoading: false,
  cloneSuccess: false,
  cloneError: false,

  paidLoading: false,
  paidSuccess: false,
  paidError: false,

  currencyConversionLoading: false,
  currencyConversionSuccess: false,
  currencyConversionError: false,

  paidByNumberLoading: false,
  paidByNumberSuccess: false,
  paidByNumberError: false,

  notaData: null,
  notaLoading: false,
  notaError: false,

  receiptSuccess: false,
  receiptLoading: false,
  receiptError: false,
};

export default function NotaFiscalRedemption(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_NOTAS_REDEMPTION:
      return {
        ...state,
        loading: true,
        error: false,
        data: [],
      };
    case Types.GET_NOTAS_REDEMPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
        amount: action.payload.total,
      };
    case Types.GET_NOTAS_REDEMPTION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      };

    case Types.PUT_NOTAS_REDEMPTION:
      return {
        ...state,
        putLoading: true,
        putSuccess: false,
        putError: false,
      };
    case Types.PUT_NOTAS_REDEMPTION_SUCCESS:
      return {
        ...state,
        putLoading: false,
        putSuccess: true,
        putError: false,
      };
    case Types.PUT_NOTAS_REDEMPTION_ERROR:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: true,
      };
    case Types.PUT_NOTAS_REDEMPTION_RESET:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: false,
      };

    case Types.CLONE_NOTAS:
      return {
        ...state,
        cloneLoading: true,
        cloneSuccess: false,
        cloneError: false,
      };
    case Types.CLONE_NOTAS_REDEMPTION_SUCCESS:
      return {
        ...state,
        cloneLoading: false,
        cloneSuccess: true,
        cloneError: false,
      };
    case Types.CLONE_NOTAS_REDEMPTION_ERROR:
      return {
        ...state,
        cloneLoading: false,
        cloneSuccess: false,
        cloneError: true,
      };
    case Types.CLONE_NOTAS_REDEMPTION_RESET:
      return {
        ...state,
        cloneLoading: false,
        cloneSuccess: false,
        cloneError: false,
      };

    case Types.PAID_NOTAS_REDEMPTION:
      return {
        ...state,
        paidLoading: true,
        paidSuccess: false,
        paidError: false,
      };
    case Types.PAID_NOTAS_REDEMPTION_SUCCESS:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: true,
        paidError: false,
      };
    case Types.PAID_NOTAS_REDEMPTION_ERROR:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: false,
        paidError: true,
      };
    case Types.PAID_NOTAS_REDEMPTION_RESET:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: false,
        paidError: false,
      };

    case Types.CURRENCY_CONVERSION_NOTAS_REDEMPTION:
      return {
        ...state,
        currencyConversionLoading: true,
        currencyConversionSuccess: false,
        currencyConversionError: false,
      };
    case Types.CURRENCY_CONVERSION_NOTAS_REDEMPTION_SUCCESS:
      return {
        ...state,
        currencyConversionLoading: false,
        currencyConversionSuccess: true,
        currencyConversionError: false,
      };
    case Types.CURRENCY_CONVERSION_NOTAS_REDEMPTION_ERROR:
      return {
        ...state,
        currencyConversionLoading: false,
        currencyConversionSuccess: false,
        currencyConversionError: true,
      };
    case Types.CURRENCY_CONVERSION_NOTAS_REDEMPTION_RESET:
      return {
        ...state,
        currencyConversionLoading: false,
        currencyConversionSuccess: false,
        currencyConversionError: false,
      };

    case Types.PAID_NOTAS_REDEMPTION_BY_NUMBER:
      return {
        ...state,
        paidByNumberLoading: true,
        paidByNumberSuccess: false,
        paidByNumberError: false,
      };
    case Types.PAID_NOTAS_REDEMPTION_BY_NUMBER_SUCCESS:
      return {
        ...state,
        paidByNumberLoading: false,
        paidByNumberSuccess: true,
        paidByNumberError: false,
      };
    case Types.PAID_NOTAS_REDEMPTION_BY_NUMBER_ERROR:
      return {
        ...state,
        paidByNumberLoading: false,
        paidByNumberSuccess: false,
        paidByNumberError: true,
      };
    case Types.PAID_NOTAS_REDEMPTION_BY_NUMBER_RESET:
      return {
        ...state,
        paidByNumberLoading: false,
        paidByNumberSuccess: false,
        paidByNumberError: false,
      };
    case Types.GET_NOTA_REDEMPTION_BY_ID:
      return {
        ...state,
        notaData: null,
        notaLoading: true,
        notaError: false,
      };
    case Types.GET_NOTA_REDEMPTION_BY_ID_SUCCESS:
      return {
        ...state,
        notaData: action.payload,
        notaLoading: false,
        notaError: false,
      };
    case Types.GET_NOTA_REDEMPTION_BY_ID_ERROR:
      return {
        ...state,
        notaData: null,
        notaLoading: false,
        notaError: action.payload,
      };
    case Types.GET_NOTA_REDEMPTION_BY_ID_RESET:
      return {
        ...state,
        notaData: null,
        notaLoading: false,
        notaError: false,
      };
    case Types.RECEIPT_UPLOAD_REDEMPTION:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: true,
        receiptError: false,
      };
    case Types.RECEIPT_UPLOAD_REDEMPTION_SUCCESS:
      return {
        ...state,
        receiptSuccess: true,
        receiptLoading: false,
        receiptError: false,
      };
    case Types.RECEIPT_UPLOAD_REDEMPTION_ERROR:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: false,
        receiptError: action.payload,
      };
    case Types.RECEIPT_UPLOAD_REDEMPTION_RESET:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: false,
        receiptError: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getNotasRedemption: (payload) => ({
    type: Types.GET_NOTAS_REDEMPTION,
    payload,
  }),
  getNotasRedemptionSuccess: (payload) => ({
    type: Types.GET_NOTAS_REDEMPTION_SUCCESS,
    payload,
  }),
  getNotasRedemptionError: () => ({
    type: Types.GET_NOTAS_REDEMPTION_ERROR,
  }),

  putNotasRedemption: (payload) => ({
    type: Types.PUT_NOTAS_REDEMPTION,
    payload,
  }),
  putNotasRedemptionSuccess: () => ({
    type: Types.PUT_NOTAS_REDEMPTION_SUCCESS,
  }),
  putNotasRedemptionError: () => ({
    type: Types.PUT_NOTAS_REDEMPTION_ERROR,
  }),
  putNotasRedemptionReset: () => ({
    type: Types.PUT_NOTAS_REDEMPTION_RESET,
  }),

  cloneNotasRedemption: (payload) => ({
    type: Types.CLONE_NOTAS_REDEMPTION,
    payload,
  }),
  cloneNotasRedemptionSuccess: () => ({
    type: Types.CLONE_NOTAS_REDEMPTION_SUCCESS,
  }),
  cloneNotasRedemptionError: () => ({
    type: Types.CLONE_NOTAS_REDEMPTION_ERROR,
  }),
  cloneNotasRedemptionReset: () => ({
    type: Types.CLONE_NOTAS_REDEMPTION_RESET,
  }),
  paidNFRedemption: (payload) => ({
    type: Types.PAID_NOTAS_REDEMPTION,
    payload,
  }),

  paidNFRedemptionSuccess: () => ({
    type: Types.PAID_NOTAS_REDEMPTION_SUCCESS,
  }),
  paidNFRedemptionError: () => ({
    type: Types.PAID_NOTAS_REDEMPTION_ERROR,
  }),
  paidNFRedemptionReset: () => ({
    type: Types.PAID_NOTAS_REDEMPTION_RESET,
  }),
  paidNFRedemptionByNumber: (payload) => ({
    type: Types.PAID_NOTAS_REDEMPTION_BY_NUMBER,
    payload,
  }),

  currencyConversionNFRedemption: () => ({
    type: Types.CURRENCY_CONVERSION_NOTAS_REDEMPTION
  }),
  currencyConversionNFRedemptionSuccess: () => ({
    type: Types.CURRENCY_CONVERSION_NOTAS_REDEMPTION_SUCCESS,
  }),
  currencyConversionNFRedemptionError: () => ({
    type: Types.CURRENCY_CONVERSION_NOTAS_REDEMPTION_ERROR,
  }),
  currencyConversionNFRedemptionReset: () => ({
    type: Types.CURRENCY_CONVERSION_NOTAS_REDEMPTION_RESET,
  }),

  paidNFRedemptionByNumberSuccess: (payload) => ({
    type: Types.PAID_NOTAS_REDEMPTION_BY_NUMBER_SUCCESS,
    payload,
  }),
  paidNFRedemptionByNumberError: () => ({
    type: Types.PAID_NOTAS_REDEMPTION_BY_NUMBER_ERROR,
  }),
  paidNFRedemptionByNumberReset: () => ({
    type: Types.PAID_NOTAS_REDEMPTION_BY_NUMBER_RESET,
  }),
  getNotaRedemptionById: (payload) => ({
    type: Types.GET_NOTA_REDEMPTION_BY_ID,
    payload,
  }),
  getNotaRedemptionByIdSuccess: (payload) => ({
    type: Types.GET_NOTA_REDEMPTION_BY_ID_SUCCESS,
    payload,
  }),
  getNotaRedemptionByIdError: (payload) => ({
    type: Types.GET_NOTA_REDEMPTION_BY_ID_ERROR,
    payload,
  }),
  getNotaRedemptionByIdReset: () => ({
    type: Types.GET_NOTA_REDEMPTION_BY_ID_RESET,
  }),
  receiptUploadRedemption: (payload) => ({
    type: Types.RECEIPT_UPLOAD_REDEMPTION,
    payload,
  }),
  receiptUploadRedemptionSuccess: () => ({
    type: Types.RECEIPT_UPLOAD_REDEMPTION_SUCCESS,
  }),
  receiptUploadRedemptionError: (payload) => ({
    type: Types.RECEIPT_UPLOAD_REDEMPTION_ERROR,
    payload,
  }),
  receiptUploadRedemptionReset: () => ({
    type: Types.RECEIPT_UPLOAD_REDEMPTION_RESET,
  }),
};
